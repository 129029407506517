import React from 'react';
import Layout from '../components/layout';
import Banner from '../components/banner';
import Footer from '../components/footer';
import { Link } from "gatsby";

import useHomeMetadata from '../hooks/useHomeMetadata';
import useCategories from '../hooks/useCategories';
import usePhones from '../hooks/usePhones';
import usePages from '../hooks/usePages'


const MapaDelSitio = () => {

    const metadata = useHomeMetadata();
    const categories = useCategories();
    const phones = usePhones();
    const pagesQuery = usePages()
    const pages = pagesQuery.allSitePage.nodes
    console.log(pages);

    const enlacesContainerStyle = {
        display: "flex",
        flexWrap: "wrap",
        padding:"50px"
      };
    
      const enlacesListStyle = {
        listStyle: "none",
        padding: 0,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        
      };
    
      const enlaceItemStyle = {
        flexBasis: "calc(48% - 10px)", // Tres columnas con espacio entre ellas
        marginBottom: "10px",
        
        padding:"10px",
        
      };
    return ( 
        <Layout lan='es' >
            <Banner 
               phones={phones[0].phones}
            />
            <main>
            <div style={enlacesContainerStyle}>
      <h2>Enlaces:</h2>
      <ul style={enlacesListStyle}>
        {pages.map((ruta, index) => (
          <li key={index} style={enlaceItemStyle}>
            <Link to={ruta.path}>{ruta.path}</Link>
          </li>
        ))}
      </ul>
    </div>
            </main>
            <Footer 
                h3_section={metadata[0].category_h3_section}
                p_section={metadata[0].category_p_section}
                categories={categories}
                lan='es'
            />
        </Layout>
    );
}
 
export default MapaDelSitio;
